<template>
    <div class="page-prepay-mobile-preactivation-preactivation">
        <div class="board">
            <div class="form-container">
                <div class="form-group">
                    <div class="form-controls">
                        <app-input
                            v-model="imsi_range_start"

                            label="IMSI Range Start"

                            :mask="phone_number_mask"

                            :error="errors.fields.imsi_range_start"

                            @change="errors.fields.imsi_range_start = null"
                        />
                    </div>

                    <div class="form-controls">
                        <app-input
                            v-model="imsi_range_end"

                            label="IMSI Range End"

                            :mask="phone_number_mask"

                            :error="errors.fields.imsi_range_end"

                            @change="errors.fields.imsi_range_end = null"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <div class="form-controls base-plan">
                        <app-dropdown-select
                            v-model="base_plan_uuid"
                            :options="base_plan_options"

                            key-value="UUID"
                            key-title="Name"

                            label="Base Plan"

                            :error="errors.fields.base_plan_uuid"

                            @change="errors.fields.base_plan_uuid = null"
                        />
                    </div>
                </div>
            </div>

            <div class="form-container">
                <div class="form-group">
                    <div class="form-controls addons">
                        <h5 class="heading">Addons</h5>

                        <div class="form" v-if="is_addons_for_adding_available">
                            <app-dropdown-select
                                v-model="addon_uuid"

                                label="Add new addon"

                                :options="addons_for_adding"

                                key-value="UUID"
                                key-title="Name"
                            />

                            <button class="btn btn-primary btn-small btn-add-addon" :disabled="!addon_uuid" @click="addAddon">Add</button>
                        </div>

                        <ul v-if="is_current_addons_available">
                            <li v-for="(addon, key) in current_addons" :key="key">
                                <span class="addon-name">{{ addon.Name }}</span>

                                <div class="actions">
                                    <button class="btn btn-table-action action-secondary action-remove" @click="removeAddon(addon)"></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <button class="btn btn-primary btn-small" @click="submit">Submit</button>
        </div>
    </div>
</template>

<script>
import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import PrepayMobileValidator from '@/validators/prepay-mobile-validator'

import TEST_PLANS from './test-plans.json'
import TEST_ADDONS from './test-addons.json'

export default {
    components: {
        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            imsi_range_start: null,
            imsi_range_end: null,

            base_plan_uuid: null,

            plans: TEST_PLANS,   // @todo []
            addons: TEST_ADDONS, // @todo []

            addon_uuid: null,

            current_addons: {},

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    methods: {
        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                imsi_range_start: { rule: 'PhoneNumber', message: 'Please, enter valid phone number' },
                imsi_range_end:   { rule: 'PhoneNumber', message: 'Please, enter valid phone number' },
            }

            for (const key in fields) {
                if (PrepayMobileValidator.isRuleExists(fields[key].rule)) {
                    if (PrepayMobileValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors.fields[key] = PrepayMobileValidator.getError()
                        is_valid = false
                    }
                }
            }

            if (!this.base_plan_uuid) {
                this.errors.fields.base_plan_uuid = 'Please, select base plan'
                is_valid = false
            }

            if (!this.errors.fields.imsi_range_start && !this.errors.fields.imsi_range_end) {
                if (+this.imsi_range_start >= +this.imsi_range_end) {
                    this.$store.dispatch('addToast', {
                        message: '"IMSI Range End" must be greater than "IMSI Range Start"',
                        type: 'error',
                        delay: 5000,
                    })

                    is_valid = false
                }
            }

            return is_valid
        },

        submit() {
            if (this.validation()) {
                this.$router.push({ name: 'prepay-mobile-preactivation-previous-reservations' })

                /**
                 * @todo
                 */
            }
        },

        addAddon() {
            this.$set(this.current_addons, this.addon_uuid, {
                UUID: this.addon_uuid,
                Name: this.addons_options[this.addon_uuid].Name,
            })

            this.addon_uuid = null
        },

        removeAddon(addon) {
            if (addon.UUID in this.current_addons) {
                this.$delete(this.current_addons, addon.UUID)
            }
        },
    },

    computed: {
        phone_number_mask() {
            return '999999999999'
        },

        base_plan_options() {
            return this.plans.reduce((obj, item) => {
                obj[item.UUID] = { ...item }
                return obj
            }, {})
        },

        addons_options() {
            return this.addons.reduce((obj, item) => {
                obj[item.UUID] = { ...item }
                return obj
            }, {})
        },

        addons_for_adding() {
            const addons_for_adding = { ...this.addons_options }

            for (const key in this.current_addons) {
                delete addons_for_adding[key]
            }

            return addons_for_adding
        },

        is_addons_for_adding_available() {
            return Object.keys(this.addons_for_adding).length > 0
        },

        is_current_addons_available() {
            return Object.keys(this.current_addons).length > 0
        },
    },
}
</script>

<style lang="scss">
.page-prepay-mobile-preactivation-preactivation {
    .board {
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        .form-container {
            max-width: 1162px;

            margin-bottom: 40px;

            .form-group {
                display: flex;
                flex-wrap: wrap;

                margin-left: -15px;
                margin-right: -15px;

                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }

                .form-controls {
                    flex: 0 0 50%;
                    max-width: 50%;

                    padding: 0 15px;
                }
            }
        }

        .addons {
            h5 {
                &.heading {
                    margin-bottom: 24px;
                }
            }

            .form {
                display: flex;

                .btn-add-addon {
                    margin: 0 0 0 30px;
                }
            }

            ul {
                margin-top: 24px;

                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    padding: 16px 0;

                    border-bottom: 1px solid var(--color-divider);

                    .addon-name {
                        flex-grow: 1;

                        @include text-overflow();
                    }

                    .actions {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-shrink: 0;

                        margin-left: 8px;

                        .btn {
                            margin-left: 16px;

                            &:first-child { margin: 0; }
                        }
                    }

                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-prepay-mobile-preactivation-preactivation {
        .board {
            .form-container {
                .form-group {
                    .form-controls {
                        &.base-plan,
                        &.addons {
                            flex: 0 0 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-prepay-mobile-preactivation-preactivation {
        .board {
            padding: 24px 8px;

            .form-container {
                margin-bottom: 30px;

                .form-group {
                    margin-bottom: 24px;

                    .form-controls {
                        flex: 0 0 100%;
                        max-width: 100%;

                        margin-bottom: 24px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &:last-of-type {
                    margin-bottom: 24px;
                }
            }

            .addons {
                .form {
                    flex-direction: column;

                    .btn-add-addon {
                        margin: 24px 0 0 0;
                    }
                }

                ul {
                    margin-top: 16px;

                    li {
                        padding: 8px 0;

                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>